.footer-section {
    background: var(--black);
}

.footer-section-inner {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.footer-section .footer-left {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer-top {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.footer-top .footer-left .custom-heading {
    color: var(--white);
    font-size: 20px;
}
.footer-top .footer-left .custom-text {
    color: var(--white-700);
    font-size: 14px;
}

.footer-top .footer-right ul {
    display: flex;
    margin-bottom: 0;
    gap: 15px;
    flex-wrap: wrap;
    padding-left: 0;
}

.footer-top .footer-right ul li img {
    filter: brightness(0.5);
    transition: all .3s ease-in-out;
}

.footer-top .footer-right ul li img:hover {
   filter: none;
}

/**footer center**/

.footer-center label {
    color: var(--white);
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 10px;
}

.footer-center button.common-btn-item svg {
    width: 25px;
    height: 25px;
    padding-right: 5px;
}


.footer-center .custom-input {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    /* justify-content: center; */
}


.footer-center .custom-input .form-control {
    max-width: 100%;
    height: auto;
    max-width: 100%;
    height: auto;
    width: 500px;
    padding: 12px 15px;

}
.footer-center .custom-input .common-btn-item {
    height: 50px;
}


/***footer bottom section***/

.footer-bottom {
    padding-top: 30px;
}

.footer-bottom ul {
    display: flex;
    padding-left: 0;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2px;
    margin-bottom: 0;
    padding-bottom: 30px;   
    text-align: center;
}

.footer-bottom ul li a {
    color: var(--white-700);
    transition: all .3s ease-in-out;
    font-size: 14px;
}

.footer-bottom ul li.active a {
    color: var(--white);
    text-decoration: underline;
    text-underline-offset: 6px;
}

.footer-bottom ul li:hover a{
    color: var(--white);
    text-decoration: underline;
    text-underline-offset: 6px;
}

.footer-bottom ul li span {
    color: var(--white-700);
    transition: all .3s ease-in-out;
    padding: 0 8px;
}

.footer-copyright .custom-heading.ch-small {
    /* text-align: center; */
    justify-content: center;
    color: var(--white-700);
    font-weight: 400;
}

@media only screen and (max-width: 767px) { 

    .footer-section-inner {
        gap: 15px;
    }
  
    .footer-top {
        justify-content: center;
    }

    .footer-top .footer-left .custom-heading {
       justify-content: center;
    }

    .footer-center .custom-input {
        justify-content: center;
    }

    .footer-section .footer-left {
        text-align: center;
    }
    .footer-bottom {
        padding-top: 0;
    }

    .footer-bottom ul li span {
        padding: 0 3px;
    }


    
    
}