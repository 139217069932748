html {
    scroll-behavior: initial !important;
}

/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap'); */

* {
    text-decoration: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a,
a:hover {
    text-decoration: none;
    color: var(--black);
}

ul li {
    list-style: none;
}

img,
svg {
    vertical-align: middle;
    max-width: 100%;
}


.common-word-wrap {
    word-wrap: break-word;
}

.d-none {
    display: none;
}

.d-block {
    display: block;
}

.icon svg {
    width: 24px;
    height: 24px;
}

.row-gap {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.flex-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    border-top: 1px solid rgb(224, 224, 224);
    border-bottom: 1px solid rgb(224, 224, 224);
    padding: 15px 2px;
}

.justify-center {
    justify-content: center;
}

.red {
    color: var(--red);
}

.border-right {
    border-right: 1px solid rgb(224, 224, 224);
}

.white {
    color: var(--white) !important;
}

/***breadcrumb Start***/

.breadcrumb {
    display: flex;
    list-style: none;
    padding-top: 20px;
    font-size: 14px;
    margin-bottom: 0;
}

.breadcrumb-item a {
    text-decoration: none;
    color: var(--input-gray);
    font-weight: 500;
    letter-spacing: 0.1px;
    transition: all .3s ease-in-out;
}

.breadcrumb-item a:hover {
    color: var(--light-blue);
}

.breadcrumb-item.active {
    color: var(--light-blue);
    font-weight: 600;
    letter-spacing: 0.1px;
}

/***breadcrumb End***/


/***link hover effect css start***/

.link {
    font-size: 13px;
    color: var(--black);
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: all 0.5s ease 0s;
    font-family: "Vogue Avant Garde";
    letter-spacing: 0.127811em;
}

.link:hover,
.link:focus {
    color: var(--light-blue);
    ;
}

.link:before {
    content: '';
    /* background: #d63031; */
    height: 2px;
    width: 0;
    position: absolute;
    left: 0;
    bottom: -4px;
    z-index: -1;
    transition: all 0.5s ease 0s;
    animation: animate 1s infinite;
}

.link:hover:before {
    width: 100%;
}

.active .link {
    color: var(--light-blue);
}

.active .link:before {
    width: 100%;
}

@keyframes animate {
    0% {
        background-color: var(--light-blue);
    }

    20% {
        background-color: var(--light-blue);
    }

    40% {
        background-color: var(--light-blue);
    }

    60% {
        background-color: var(--light-blue);
    }

    80% {
        background-color: var(--light-blue);
    }

    100% {
        background-color: var(--light-blue);
    }
}

/* @media only screen and (max-width: 767px) {
    .link {
        margin-bottom: 30px;
    }
} */

/***link hover effect end***/



/**common spaces Start***/

.sp-s-4 {
    padding-left: 20px;
}

.sp-e-4 {
    padding-right: 20px;
}

.padding-50 {
    padding: 50px 0;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-25 {
    padding: 25px 0;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.margin-50 {
    margin: 50px 0;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-25 {
    margin: 25px 0;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}


@media only screen and (max-width: 767px) {

    .sp-s-4 {
        padding-left: 12px;
    }

    .sp-e-4 {
        padding-right: 12px;
    }

    .padding-50 {
        padding: 25px 0;
    }

    .padding-top-50 {
        padding-top: 25px;
    }

    .padding-bottom-50 {
        padding-bottom: 25px;
    }

    .padding-25 {
        padding: 12.50px 0;
    }

    .padding-top-25 {
        padding-top: 12.50px;
    }

    .padding-bottom-25 {
        padding-bottom: 12.50px;
    }

    .margin-50 {
        margin: 25px 0;
    }

    .margin-top-50 {
        margin-top: 25px;
    }

    .margin-bottom-50 {
        margin-bottom: 25px;
    }

    .margin-25 {
        margin: 12.50px 0;
    }

    .margin-top-25 {
        margin-top: 12.50px;
    }

    .margin-bottom-25 {
        margin-bottom: 12.50px;
    }

}

/**common spaces End***/

/**common button***/

.common-btn-item {
    align-items: center;
    border: none;
    border-radius: 4px;
    color: var(--black-800);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    justify-content: center;
    letter-spacing: 1.25px;
    min-width: 100px;
    padding: 0 20px;
    position: relative;
    transition: all .3s ease-in-out;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}

.common-btn-item.cbi-solid {
    background: var(--light-blue);
    color: var(--white);
}

.common-btn-item.cbi-solid:hover {
    background: var(--light-blue-hover);
    box-shadow: 0 2px 8px 0 #63636333;
    color: var(--white);
}

.common-btn-item.cbi-outline {
    border: 1px solid var(--light-blue);
    color: var(--light-blue);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.common-btn-item.cbi-outline:hover {
    background: var(--light-blue);
    color: var(--white);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


/*--zoom effect--*/
.zoom-img {
    width: 100%;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.zoom-img img {
    width: 100%;
    transition: all .3s ease-in-out;
}

.zoom-img img:hover {
    transform: scale(1.04);
}

/* --- Website Fonts --- */
@font-face {
    font-family: "Montserrat", sans-serif;
    src: url("../fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
    font-style: 600;
}

@font-face {
    font-family: "Anton", sans-serif;
    src: url("../fonts/Anton/Anton-Regular.ttf");
    font-style: bold;
}

@font-face {
    font-family: "Mulish";
    src: url("../fonts/Mulish/Mulish-VariableFont_wght.ttf");
    font-style: normal;
    font-weight: 200 1000;
}

@font-face {
    font-family: "Vogue Avant Garde";
    src: url("../fonts/AGaramond/vogue-avant-garde-light.otf");
    font-style: normal;
    font-weight: normal;
}



/* --- Body tag Css --- */
body {
    padding: 0;
    margin: 0;
    font-family: "Mulish";
    /* font-family: 'Adobe Garamond Pro Regular'; */
    font-weight: 400;
    font-size: 16px;
    color: var(--celeste);
}

.overflowhidden,
body.overflowhidden {
    overflow: hidden;
}

/*-----common btn start--------*/
.common-btn {
    align-items: center;
    background: var(--white);
    border: 1px solid var(--white);
    border-radius: 20px;
    color: var(--black);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    letter-spacing: 1px;
    line-height: 30px;
    padding: 5px 20px;
    position: relative;
    transition: all .3s ease-in-out;
    width: -moz-fit-content;
    width: fit-content;
}

.common-btn:hover {
    background-color: var(--white-900);
    border-color: var(--white-900);
}


@media only screen and (max-width: 767px) {
    .common-btn {
        font-size: 14px;
        padding: 5px 10px;
        height: 35px;
    }

}

/* common heading Start*/

.custom-heading {
    color: var(--black);
    font-size: 26px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 35px;
    transition: all .3s ease-in-out;
    letter-spacing: 0.5px;
}

.custom-text {
    color: var(--black);
    font-size: 20px;
    color: var(--input-gray);
    transition: all .3s ease-in-out;
    letter-spacing: 0.5px;
}

.custom-text.ct-medium {
    font-size: 18px;
}

.custom-text.ct-small {
    font-size: 16px;
}


.custom-text.ct-xssmall {
    font-size: 14px;
}

.custom-heading.ch-medium {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    /* color: var(--light-blue); */
    color: #000;
    transition: all .3s ease-in-out;
}

.custom-heading.ch-small {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--light-blue);
    transition: all .3s ease-in-out;
    letter-spacing: 0.5px;
}

/*---common news section---*/

.common-news-box.row-gap {
    gap: 20px;
}

.common-news-box .cnb-box {
    /* border-top: 1px solid #eaeaea; */
    padding: 5px 0;
}

.common-news-box .cnb-box:first-child {
    margin-top: 0;
    padding: 5px 0 0;
    border: none;
}

.common-news-box .cnb-box .cnb-inner {
    display: flex;
    /* align-items: center; */
    gap: 15px;
}

.common-news-box .cnb-box .cnb-inner .cnb-img {
    flex: 0 0 100px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 0;
    /* display: flex; */
    justify-content: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px
}

.common-news-box .cnb-box .cnb-inner .cnb-img img {
    height: 75px;
    border-radius: 0;
    object-fit: cover;
    width: 100%;
    /* overflow: hidden; */
    transition: transform 0.5s ease;
}

.common-news-box .cnb-box .cnb-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    word-break: break-all;
    flex: 1 1;
}

.common-news-box .cnb-box .cnb-content .custom-heading.ch-medium {
    font-size: 18px;
    color: var(--black);
    font-weight: 600;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    transition: all .3s ease-in-out;
}

.common-news-box .cnb-box .cnb-content .custom-heading.ch-small {
    font-size: 14px;
    color: var(--input-gray);
    font-weight: 400;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    transition: all .3s ease-in-out;
}

.common-news-box .cnb-box .cnb-content .custom-text {
    color: var(--light-s-gray);
    font-size: 14px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 100%;
    transition: all .3s ease-in-out;
}

/* .common-news-box .cnb-box .cnb-inner:hover .cnb-img img {
    transform: scale(1.04);
} */

.common-news-box .cnb-box .cnb-inner:hover .custom-heading {
    color: var(--light-blue);
    text-decoration: underline;
    text-underline-offset: 3px;
}

.category-section .flex-box {
    border: none;
}

/*---homepage css start-----*/

.common-infromation {
    padding: 8px 25px 8px;
    background: #ebebeb;
    /* box-shadow: 0 0 2px var(--black-200); */
    /* position: sticky;
    top: 80px;
    background: #fff;
    z-index: 99; */
}

.common-infromation .cni-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.common-infromation .cni-inner .cni-datetime {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    font-family: "Vogue Avant Garde";
}

.common-infromation .cni-detail .react-datepicker__tab-loop {
    position: absolute;
}

.common-infromation .react-datepicker__day--selected,
.common-infromation .react-datepicker__day--selected:hover {
    background-color: var(--black);
}

.common-infromation .react-datepicker-popper {
    line-height: 0;
    z-index: 1;
    left: -22px !important;
}

.common-infromation .cni-detail {
    display: flex;
    gap: 10px;
    align-items: center;
}

.common-infromation .cni-inner .cni-datetime span img {
    width: 36px;
    height: auto;
}

.common-infromation .cni-detail svg {
    width: 22px;
    height: 22px;
}

.common-infromation .cni-mode .theme-toggle {
    width: 40px;
    height: 40px;
    padding: 7px;
    cursor: pointer;
    text-align: center;
}

.common-infromation .cni-inner .cni-datetime .react-datepicker__input-container {
    font-family: "Vogue Avant Garde";
}

/*----breakingnews start----*/


.breakingnews-section .brk-left .custom-heading.ch-medium {
    border-top: 1px solid rgb(224, 224, 224);
    border-bottom: 1px solid rgb(224, 224, 224);
    padding: 15px 0;
}

.breakingnews-section .brk-slider {
    width: 95%;
    margin: auto;
}

.breakingnews-section .brk-slider .custom-arrow {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
}

.breakingnews-section .brk-slider .custom-arrow svg {
    width: 30px;
    height: 30px;
    transition: fill 0.3s ease;
    color: var(--black-50);
}

.breakingnews-section .brk-slider .news-card {
    /* display: flex !important; */
    flex-direction: column;
    align-items: start;
    gap: 30px;
    padding: 0 15px;
}

.breakingnews-section .brk-slider .custom-arrow.custom-next {
    right: -25px;
}

.breakingnews-section .brk-slider .custom-arrow.custom-prev {
    left: -25px;
}

.breakingnews-section .news-content .custom-text {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.breakingnews-section .news-card:hover .news-content .custom-heading {
    color: var(--light-blue);
    transition: all .3s ease-in-out;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.breakingnews-section .brk-slider .news-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 0;
}

.breakingnews-section .brk-slider .news-content .custom-heading {
    display: block;
}

.breakingnews-section .brk-slider .news-image {
    width: 100%;
    /* height: 400px; */
    height: 100%;
    border-radius: 0;
    overflow: hidden;
    object-fit: cover;
    /* margin-bottom: 20px; */
}

.breakingnews-section .brk-slider .news-image img {
    width: 100%;
}


.breakingnews-section .brk-slider .news-heading {
    font-size: 1.5rem;
    margin: 0;
}

.breakingnews-section .brk-slider .news-text {
    font-size: 1rem;
    color: #555;
}

.breakingnews-section .slick-prev:before,
.slick-next:before {
    color: var(--black);
}

.brk-trading .custom-heading.ch-small {
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.brk-trading .custom-heading.ch-small a:hover {
    color: var(--light-blue);
    transition: all .3s ease-in-out;
}

.breakingnews-section .brklist-trading .brklt-box img {
    width: 100%;
    height: 100%;
    border-radius: 0;
    transition: transform 0.5s ease;
    object-fit: cover;
}

.breakingnews-section .brklist-trading .brklt-box {
    display: flex;
    gap: 15px;
}

.breakingnews-section .brklist-trading .brkit-img {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    flex: 0 0 90px;
    height: 70px;
    width: 70px;

}

/* .breakingnews-section .brklist-trading .brklt-box:hover .brkit-img img {
    transform: scale(1.04);
} */


.breakingnews-section .brklt-inner.row-gap {
    /* gap: 25px; */
    gap: 15px;
}

.breakingnews-section .brklist-trading .brklt-box:hover .custom-heading {
    color: var(--light-blue);
    transition: all .3s ease-in-out;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.breakingnews-section .brklt-box .brklt-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex: 1;
}


.breakingnews-section .brklist-trading .brklt-box .zoom-img {
    overflow: hidden;
    width: 178px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    display: flex;
    justify-content: center;
}

.breakingnews-section .brklist-trading .brklt-box:hover .zoom-img img {
    transform: scale(1.2);
}


.breakingnews-section .brklist-trading .custom-heading.ch-medium {
    font-size: 16px;
    color: var(--black);
    font-weight: 600;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    transition: all .3s ease-in-out;
    width: 212px;
}

.breakingnews-section .brklist-trading .custom-text {
    color: var(--light-s-gray);
    font-size: 14px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    width: 100%;
    transition: all .3s ease-in-out;
}

/*----breakingnews End----*/

/*---Politics New start--*/

.politicsnew-section .pls-inner .zoom-img {
    border-radius: 0;
}

.politicsnew-section .pls-inner .custom-heading {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.politicsnew-section .pls-inner:hover .custom-heading {
    color: var(--light-blue);
    text-decoration: underline;
    text-underline-offset: 3px;
}

.politicsnew-section .pls-inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.politicsnew-section .pls-inner img {
    width: 100%;
    height: 280px;
    overflow: hidden;
    object-fit: cover;
}


/*---Politics New End--*/

.adver-tisement {
    position: relative;
}

.adver-tisement .adt-inner {
    width: 300px;
    height: 600px;
    object-fit: cover;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
}

.adver-tisement .adt-inner img {
    border-radius: 0;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.068);
    max-width: 100%;
    height: 612px;
    overflow: hidden;
    object-fit: cover;
    width: 305px;
}

.adver-tisement .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--white);
    z-index: 9;
}

.adver-tisement .close-btn svg {
    width: 20px;
    height: 20px;
}

.sticky-ad {
    z-index: -5;
}

.adver-tisement {
    position: sticky;
    top: 150px;
}


/*---common News slider start--*/

.common-news-slider {
    background: rgb(0, 128, 200);
    background: linear-gradient(180deg, rgba(0, 128, 200, 1) 0%, rgba(26, 0, 51, 1) 50%, rgba(0, 63, 98, 1) 100%);
    overflow-x: hidden;
    margin: 50px 0;
    padding: 85px 0;
}

.common-news-slider .cnms-slider img {
    /* width: 400px; */
    height: 665px;
    object-fit: cover;
}

.common-news-slider .cnms-slider .slick-slide {
    margin: 0 15px;
}

.common-news-slider .cnms-slider .slick-track {
    display: inline-flex;
}

.common-news-slider .cnms-slider .slider-container {
    position: relative;
    overflow: hidden;
}

.common-news-slider .cnms-slider .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    position: relative;
}

.common-news-slider .cnms-slider a:focus-visible {
    outline: none;
}

.common-news-slider .cnms-slider .custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
}

.common-news-slider .cnms-slider .custom-prev {
    left: 0;
}

.common-news-slider .cnms-slider .custom-next {
    right: 0;
}

.common-news-slider .cnms-slider .custom-arrow svg {
    width: 40px;
    height: 40px;
    transition: fill 0.3s ease;
    color: var(--black-50);
}

.common-news-slider .cnms-slider .slide-image {
    width: 100%;
    transition: opacity 0.3s ease;
}

.common-news-slider .cnms-slider .slide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
    transition: background-color 0.3s ease;
    border-radius: 0;

}

.common-news-slider .cnms-slider .slide.active::before {
    background-color: rgba(255, 255, 255, 0);
}

.common-news-slider .cnms-slider .slide img {
    position: relative;
    z-index: 2;
    width: 450px;
    /* height: 651px; */
    height: fit-content;
    overflow: hidden;
    object-fit: cover;
    border-radius: 0;
}

.common-news-slider .cnms-slider .slide.active img {
    opacity: 1;
    z-index: 3;
}

.common-news-slider .cnms-slider .slide.inactive img {
    opacity: 0.5;
}

/*---common News slider End--*/


/*---Luxury products start--*/

.luxury-products-section .lps-inner img {
    height: 100%;
    object-fit: cover;
    border-radius: 0;
}

.luxury-products-section img {
    width: 100%;
    transition: transform 0.5s ease;
}

.luxury-products-section img:hover {
    transform: scale(1.1);
}

.luxury-products-section .lps-inner .masonry-item {
    overflow: hidden;
    position: relative;
    border-radius: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 100%;
}

.luxury-products-section .lps-inner .masonry-item img {
    display: block;
    cursor: pointer;
}


/*---Luxury products End--*/

/*--common-advertisement Start--*/

.common-advertisement {
    overflow: hidden;
    position: relative;
    max-width: 728px;
    height: 90px;
    margin: 50px auto;
}

.common-advertisement a.adt-inner img {
    object-fit: cover;
    overflow: hidden;
    display: inline-block;
    width: 100%;
    height: auto;
}

.luxury-products-section .common-advertisement a.adt-inner:hover img {
    transform: scale(1);
}

.common-advertisement .adt-inner {
    position: relative;
}

.common-advertisement .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--white);
    z-index: 9;
}

.common-advertisement .close-btn svg {
    width: 20px;
    height: 20px;
}

/*--common-advertisement End--*/

/*--sport section css start--*/


.sport-news-section .spns-inner {
    position: relative;
    overflow: hidden;
}

.sport-news-section .spns-inner img {
    width: 100%;
    border-radius: 0;
    width: 1296px;
    height: 386px;
    object-fit: cover;
}

.sport-news-section .spns-inner .custom-heading {
    position: absolute;
    top: auto;
    bottom: 0;
    text-align: center;
    backdrop-filter: blur(0px) saturate(200%);
    -webkit-backdrop-filter: blur(0px) saturate(200%);
    background-color: rgba(255, 255, 255, 0.51);
    border-radius: 5px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    max-width: 915px;
    margin: 15px auto;
    display: block;
    right: 15px;
    left: 15px;
    padding: 8px 8px;
}

.sport-news-section .common-news-box .cnb-box.latest-news .cnb-inner img {
    /* width: 575px;
    height: 342px; */
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.sport-news-section .common-news-box.row-gap {
    gap: 22px;
}

.sport-news-section .cnb-box.latest-news .custom-heading {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.sport-news-section .cnb-box.latest-news .cnb-content {
    gap: 15px;
}

.sport-news-section .common-news-box .cnb-box .cnb-inner {}

/*--sport section css End--*/


/*mothof story start css*/
.month-story {
    background: rgb(0, 128, 200);
    background: linear-gradient(270deg, rgba(0, 128, 200, 1) 0%, rgba(0, 0, 0, 1) 57%, rgba(0, 63, 98, 1) 99%);
    padding: 150px 0;
}

.month-story .ms-inner .custom-heading {
    display: block;
    font-family: "Anton", sans-serif;
    font-size: 83px;
    line-height: 123px;
    text-align: right;
    font-weight: 800;
    color: var(--white);
}

.month-story .ms-inner .ms-img {
    width: 400px;
    height: 500px;
    overflow: hidden;
    object-fit: cover;
}

.month-story .ms-inner .custom-text {
    color: var(--white);
    font-size: 24px;
    height: 510px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.month-story .ms-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.month-story .ms-img img {
    width: 100%;
    height: auto;
}

.month-story .slick-vertical .slick-slide {
    width: 100% !important;
}

@media (min-width: 768px) {
    .month-story .ms-inner {
        flex-direction: row;
    }

    .month-story .custom-text {
        margin-top: 0;
        font-size: 18px;
    }
}

/*mothof story end css*/

/*top story css Start*/

.top-story-section {
    overflow-x: hidden;
}

.top-story-section .tps-inner {
    padding-top: 15px;
}

.top-story-section .tps-inner .common-news-box {
    padding: 0 25px 0 0;
}

.top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img {
    flex: 0 0 426px;
}

.top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .cnb-img img {
    /* height: 364px; */
    height: 100%;
}

.top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner {
    align-items: start;
}

.top-story-section .tps-inner .common-news-box .cnb-box .cnb-content {
    gap: 15px;
}

.top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner .custom-heading {
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.top-story-section .tps-inner .common-news-box .cnb-box .cnb-inner:hover .custom-heading {
    color: var(--light-blue);
}

.top-story-section .tps-inner .common-news-box .cnb-box .cnb-content .custom-text {
    font-size: 18px;
}

/*top story css end*/

/* .market-section .mrs-inner {
    border-top: 1px solid var(--border-gray);
    padding: 25px 0;
    border-bottom: 1px solid var(--border-gray);
} */

.market-section .mrs-detail {
    padding: 30px 0 30px 0;
    border-bottom: 1px solid rgb(224, 224, 224);
}

.market-section .mrs-img {
    padding-bottom: 20px;
}

.market-section .mrs-img img {
    width: 1011px;
    height: 649px;
    overflow: hidden;
    object-fit: cover;
    border-radius: 0;
    transition: transform 0.5s ease;
}

.market-section .mrs-img:hover img {
    transform: scale(1.04);
}

.market-section .mrs-img:hover .custom-heading {
    color: var(--light-blue);
    text-decoration: underline;
    text-underline-offset: 3px;
}

.market-section .mrs-img-inner {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 0;
    /* display: flex; */
    justify-content: center;
}

.market-section .mrs-detail .common-news-box .cnb-box .cnb-inner {
    align-items: start;
}

.market-section .mrs-detail .common-news-box.upcoming-news .cnb-box .cnb-inner {
    flex-direction: column;
}

.market-section .mrs-detail .common-news-box.upcoming-news .cnb-box .cnb-inner .cnb-img {
    flex: 0 0 274px;
}

.market-section .mrs-detail .common-news-box.upcoming-news .cnb-box .cnb-inner .cnb-img img {
    height: 274px;
}

.market-section .mrs-detail .mrs-img .custom-heading {
    font-size: 26px;
    border-bottom: 1px solid rgb(224, 224, 224);
    padding: 15px 0;

}

/**Detail page css start**/

.detail-page .dp-inner {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.detail-page .dp-inner img {
    height: auto;
    object-fit: cover;
    border-radius: 0;
    width: 100%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/**Detail page css End**/

/**Quicklink css Start**/

.quick-links-section {
    border-top: 1px solid #ebebeb;
    padding-top: 19px;
}

.quick-links-section .quick-links-inner {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.quick-links-section .quick-links-buttons {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 10px;
}

.quick-links-section .quick-link-btn.common-btn-item.cbi-outline {
    letter-spacing: 0.5px;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 0;
    transition: transform 0.5s;
    font-weight: 500;
    height: 40px;
    min-width: 80px;
}

.quick-links-section .quick-link-btn:hover {
    transform: translateY(-2px);
}

/**Quicklink css End***/

/**activevity Start**/

.activity {
    /* display: inline-block; */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    margin-top: 10px;
    max-width: 80px;
}

.activity span {
    position: relative;
    padding-left: 10px;
    transition: all .3s ease-in-out;
}

.activity span,
.activity span a {
    color: var(--light-s-gray);
    font-size: 14px;
    transition: all .3s ease-in-out;
}

.activity a svg {
    width: 20px;
    height: 20px;
}

.activity span:first-child {
    margin-left: 0;
    padding-left: 0;
}

.activity span:hover a {
    color: var(--light-blue);
}

/**activevity End**/

/**pagination css Start**/

.pagination-section .pagination-outer {
    text-align: center;
}

.pagination-section .pagination {
    display: inline-flex;
    position: relative;
}

.pagination-section .pagination li a.page-link {
    color: var(--white);
    background: var(--black);
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    line-height: 40px;
    height: 40px;
    width: 40px;
    padding: 0;
    margin: 0 4px;
    border: none;
    border-radius: 0;
    display: block;
    position: relative;
    z-index: 0;
    transition: all .3s ease-in-out;
}

.pagination-section .pagination li:first-child a.page-link,
.pagination-section .pagination li:last-child a.page-link {
    font-size: 23px;
    line-height: 37px;
}

.pagination-section .pagination li a.page-link:hover,
.pagination-section .pagination li a.page-link:focus,
.pagination-section .pagination li.active a.page-link:hover,
.pagination-section .pagination li.active a.page-link {
    color: #fff;
    background: var(--light-blue);
}

.pagination-section .pagination li a.page-link:hover:before,
.pagination-section .pagination li a.page-link:focus:before,
.pagination-section .pagination li.active a.page-link:hover:before,
.pagination-section .pagination li.active a.page-link:before {
    background-color: var(--light-blue);
    transform: perspective(100px) rotateY(0);
}

.pagination-section .pagination li a.page-link svg {
    width: 25px;
}

@media only screen and (max-width: 480px) {
    .pagination-section .pagination {
        font-size: 0;
        /* display: inline-block; */
    }

    .pagination-section .pagination li {
        display: inline-block;
        vertical-align: top;
        /* margin: 0 0 15px; */
    }
}

/**pagination css End**/

/***detail next preview***/

.detail-next-preview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-weight: 500;
    color: var(--input-gray);
    transition: all 0.3s ease-in-out;
}

.detail-next-preview .dnp-title {
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.detail-next-preview .dnp-title:hover {
    color: var(--black);
}

.detail-next-preview svg {
    width: 18px;
    height: 18px;
}

.detail-next-preview .detail-preview,
.detail-next {
    position: relative;
    cursor: pointer;
    padding: 15px 15px;
    border: 1px solid #eaeaea;
    width: 100%;
}

.detail-next-preview .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--white);
    border: 1px solid #eaeaea;
    z-index: 9;
    width: 100%;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.9s ease, opacity 0.3s ease;
    display: block;
    border-radius: 0;
    padding: 0;
}

.detail-next-preview .dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.detail-next-preview .dropdown-menu li {
    padding: 10px 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #eaeaea;
}

.detail-next-preview .dropdown-menu li:last-child {
    border-bottom: none;
}

.detail-next-preview .dropdown-menu li a {
    text-decoration: none;
    color: var(--input-gray);
    display: block;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    font-size: 14px;
}

.detail-next-preview .dropdown-menu li:hover {
    background-color: #f5f5f5;
}

.detail-next-preview .dropdown-menu.open {
    max-height: 300px;
    opacity: 1;
}

.dnp-title .custom-text.ct-small {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    padding-top: 2px;
    color: var(--light-s-gray);
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    text-align: left;
    padding-left: 8px;
}

.dnp-title:hover .custom-text.ct-small {
    color: var(--black);
}

.detail-next-preview .detail-preview .dnp-title,
.detail-next-preview .detail-next .dnp-title {
    text-align: left;

}

/*inner page css start***/

.innerpages-section .flex-box {
    border: none;
}

.innerpages-section .inp-inner .custom-heading {
    margin-top: 20px;
}

.innerpages-section .inp-inner .custom-text {
    margin-top: 15px;
}

/**inner page css End***/


/**Contactus page css start***/

.contact-box .flex-box {
    border: none;
}

.contact-us-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    max-width: 1000px;
    margin: 0 auto;
}

.contact-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 30px;
    background: var(--black);
    justify-content: center;
}

.contact-info .custom-text {
    color: var(--white);
}


.contact-details {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;

}

.contact-details li {
    margin-bottom: 10px;
    color: var(--white);
}

.contact-details li a {
    font-size: 16px;
    color: var(--white);
}

.contact-form {
    flex: 1;
    padding: 30px;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;
}

.input-group input,
.input-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

ul.contact-details li svg {
    width: 24px;
    height: 24px;
    color: var(--white);
    padding-right: 3px;
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

/**Contactus page css End**/

.search-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.search-modal .modal-content {
    background-color: var(--black);
    padding: 30px 50px;
    border-radius: 5px;
    width: 100%;
    max-width: 650px;
    margin: 0 15px;
    position: relative;
    animation: zoomIn 0.3s ease-in-out;
}

/* Zoom-in animation */
@keyframes zoomIn {
    from {
        transform: scale(0.7);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

/*--- Skeletant Css Start ---*/
.skeletant-bx {
    pointer-events: none;
}

.skeletant-design {
    background-color: #000;
    background-position: left;
    background-repeat: repeat;
    background-size: 1200px;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: shine-lines 1s infinite linear;
    pointer-events: none;
    border-radius: 0;
    background-image: linear-gradient(45deg, #e5e7eb 30%, #fff 50%, #e5e7eb 70%);

}

.sw-100 {
    width: 100%;
}

.sh-30 {
    height: 30px;
}

.sh-20 {
    height: 20px;
}

.sh-40 {
    height: 40px;
}

.sh-50 {
    height: 50px;
    margin-top: 7px;
}

.sh-60 {
    height: 60px;
}

.sh-70 {
    height: 70px;
    margin-top: 7px;
}

.sh-80 {
    height: 80px;
}

.sh-95 {
    height: 95px;
}

.sh-105 {
    height: 105px;
}

.sh-125 {
    height: 125px;
}

.sh-171 {
    height: 171px;
}

.sh-215 {
    height: 215px;
}

.sh-237 {
    height: 237px;
}

.sh-274 {
    height: 274px;
}

.sh-285 {
    height: 285px;
}

.sh-342 {
    height: 342px;
}

.sh-364 {
    height: 364px;
}

.sh-386 {
    height: 386px;
}

.sh-413 {
    height: 413px;
}

.sh-464 {
    height: 464px;
}

.sh-440 {
    height: 440px;
}

.sh-476 {
    height: 476px;
}

.sh-500 {
    height: 500px;
}

.sh-651 {
    height: 651px;
}

@-webkit-keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }

    100% {
        background-position: 1100px;
    }
}


/*--- Skeletant Css End ---*/

/* Non-Breaking News Skeleton Styles */
.skeletant-news-img {
    width: 100%;
    background-image: linear-gradient(45deg, #e5e7eb 30%, #f5f5f5 50%, #e5e7eb 70%);
    background-size: 200% 100%;
    animation: shine-lines 1.5s infinite linear;
    border-radius: 0;
}

.skeletant-news-title {
    width: 100%;
    background-image: linear-gradient(45deg, #e5e7eb 30%, #f5f5f5 50%, #e5e7eb 70%);
    background-size: 200% 100%;
    animation: shine-lines 1.5s infinite linear;
    border-radius: 0;
}

.skeletant-news-text {
    width: 100%;
    background-image: linear-gradient(45deg, #e5e7eb 30%, #f5f5f5 50%, #e5e7eb 70%);
    background-size: 200% 100%;
    animation: shine-lines 1.5s infinite linear;
    border-radius: 0;
}

.skeletant-news-date {
    width: 100%;
    background-image: linear-gradient(45deg, #e5e7eb 30%, #f5f5f5 50%, #e5e7eb 70%);
    background-size: 200% 100%;
    animation: shine-lines 1.5s infinite linear;
    border-radius: 0;
}

/* Shimmer animation */
@keyframes shine-lines {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}


/****SiteMap css Start***/

.sitemap-section .flex-box {
    border: none;
}

.sitemap-link-box {
    border: 1px solid #0000002e;
    padding: 15px 15px 15px 15px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 40px;
    box-shadow: rgb(33 35 38 / 10%) 0px 10px 10px -10px;
}

.sitemap-ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sitemap-ul li {
    display: inline-block;
    margin: 8px 0;
    padding: 0px 10px;
    border-right: 1px solid lightgray;
}

.sitemap-ul li a {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
    color: #525252;
}

.sitemap-ul li:last-child {
    border-right: transparent;
}

/****SiteMap css End***/

/***social sharing***/

.social-shearing ul {
    display: flex;
    padding-left: 0;
    justify-content: end;
    gap: 10px;
}

.social-shearing ul li a {
    background: var(--black-100);
    border-radius: 50%;
    font-size: 16px;
    padding: 10px;
    position: relative;
    height: 35px;
    width: 35px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.social-shearing a .icon-tag {
    position: absolute;
    background: white;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    left: 50%;
    bottom: 0px;
    transform: translate(-50%, 50px);
    box-shadow: 0px 0px 5px #0000001f;
    padding: 4px 8px;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    pointer-events: none;
}

.social-shearing a:hover .icon-tag {
    transform: translate(-50%, 35px);
    visibility: visible;
    opacity: 1;
}


.social-shearing a::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) scale(0);
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
}

.social-shearing a .icon-tag::after {
    content: "";
    width: 12px;
    height: 12px;
    background: white;
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translate(-50%, -6px) rotate(45deg);
    z-index: 3;
    box-shadow: -1px -1px 1px #0000001f;
}

.social-shearing a:hover::after {
    transform: translate(-50%, -50%) scale(1);
}

.social-shearing a:hover {
    background-color: transparent;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.social-shearing a.fb-link {
    background-color: #3b5998;
}

.social-shearing a img {
    z-index: 9;
    filter: invert(1);
}

.social-shearing a.tw-link {
    background-color: #00aced;
}

.social-shearing a.tl-link {
    background-color: #0077b5;
}

.social-shearing a.wh-link {
    background-color: #25d465;
}

.social-shearing a.fb-link .icon-tag {
    color: #3b5998;
}

.social-shearing a.tw-link .icon-tag {
    color: #00aced;
}

.social-shearing a.tl-link .icon-tag {
    color: #0077b5;
}

.social-shearing a.wh-link .icon-tag {
    color: #25d465;
}


/*-----------------
 fashion section css start
--------------------------------*/

.news-messsanory {
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 20px;
}

/* Main left section styling */
.news-messsanory .left-section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.news-messsanory .main-image {
    width: 100%;
    height: auto;
    border-radius: 0;
}

.news-messsanory .main-content {
    position: absolute;
    top: auto;
    bottom: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.55));
    padding: 25px 20px;
    text-align: center;
    left: 0;
    right: 0;
    border-radius: 0 0 6px 6px;
}

.news-messsanory .main-content h3 {
    font-size: 16px;
    color: #fff;
}

.news-messsanory .main-content h2 {
    font-size: 28px;
    margin: 10px 0 15px;
    color: #fff;
    font-weight: 700;
}

.news-messsanory .main-content p {
    font-size: 14px;
    color: #f2f2f2;
    margin-bottom: 0;
}

.news-messsanory .main-content span {
    font-size: 12px;
    color: #f2f2f2;

}

/* Right section styling */
.news-messsanory .right-section {
    display: flex;
    flex-direction: column;
}

.news-messsanory .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

/* Card styling */
.news-messsanory .card {
    position: relative;
    background: #fff;
    border-radius: 6px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    border: none;
    transition: all .3s ease-in-out;
    display: block;
}

.news-messsanory .card:hover .card-content h3 {
    color: var(--light-blue);
    text-decoration: underline;
    text-underline-offset: 3px;
}

.news-messsanory .card-image {
    width: 100%;
    height: auto;
    border-radius: 0 0 0 0;
}

.news-messsanory .card-content {
    padding: 15px 0;
}

.news-messsanory .card-content h4 {
    font-size: 14px;
    color: #7e7e7e;
    margin-bottom: 10px;
}

.news-messsanory .card-content h3 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 600;
    transition: all .3s ease-in-out;
}

.news-messsanory .card-content p {
    font-size: 14px;
    color: var(--light-s-gray);
    margin-bottom: 0;
}

.news-messsanory .card-content span {
    font-size: 13px;
    color: #999;
}

.news-messsanory .nm-inner {
    position: sticky;
    top: 80px;
}

.news-messsanory .nm-inner .nm-content {
    /* position: relative; */
    margin: 0px auto;
    width: 100%;
}

/* Responsive styling */
@media screen and (max-width: 900px) {
    .news-messsanory {
        grid-template-columns: 2fr 3fr;
    }

    .news-messsanory .grid {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 700px) {
    .news-messsanory {
        grid-template-columns: 1fr;
    }
}


.grid-container {
    columns: 2 200px;
    column-gap: 1.5rem;
    width: 100%;
    margin: 0 auto;
}

.grid-container div {
    width: 150px;
    /* margin: 0 0 24px 0; */
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
}

.grid-container div:hover img {
    filter: grayscale(0);
}

.grid-container div:hover {
    border-color: coral;
}

.grid-container div img {
    width: 100%;
    /* filter: grayscale(100%); */
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
}


/*-----------------
 fashion section css end
--------------------------------*/

/*----------
 artical css start
 --------------------*/

/* General Styles */
.main-artical .article-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    /* padding: 20px; */
    justify-content: center;
}

.main-artical .article-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.main-artical .article-card:hover .article-content .title {
    color: var(--light-blue);
    text-decoration: underline;
    text-underline-offset: 3px;
}

.main-artical .image-container {
    position: relative;
    width: 100%;
    padding-top: min(100%, var(--aspect-ratio-box-max-height, 200vh));
}

.main-artical .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-artical .icon-overlay {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px;
    border-radius: 50%;
}

.main-artical .icon-overlay i {
    color: #fff;
    font-size: 18px;
}

.main-artical .article-content {
    padding: 15px 0;
}

.main-artical .category {
    font-size: 14px;
    color: #7e7e7e;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.main-artical .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    transition: all .3s ease-in-out;
}

.main-artical .author {
    font-size: 12px;
    color: #555;
}

/* Responsive Design */

@media screen and (max-width: 1200px) { 
    .main-artical .article-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
    }


}


@media screen and (max-width: 768px) {
    .main-artical .article-grid {
        grid-template-columns: 1fr;
    }

    /* .main-artical .title {
        font-size: 16px;
    } */

    .main-artical .author {
        font-size: 12px;
    }

    .main-artical .article-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }

    .main-artical .image-container {
        height: auto;
    }
    
}

@media screen and (max-width: 600px) { 
    .main-artical .article-grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }

    .main-artical .image-container {
        height: auto;
    }
}

/* @media screen and (max-width: 1200px) {
    .main-artical .article-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }
} */

/***detail page***/

.main-detail-section {
    display: grid;
    max-width: none;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    align-items: center;
}



.mds-right-inner.main-artical .article-content {
    text-align: center;
}

.mds-right-inner.main-artical .title {
    font-size: 42px;
    line-height: 1.2em;
    padding: 1rem 25px;
    font-weight: 600;
}

.mds-right-inner.main-artical .category {
    font-size: 16px;
    color: #000;
}

.mds-right-inner.main-artical .author {
    font-size: 16px;
    color: #000;
    font-weight: 600;
}

.devider {
    border-bottom: 1px solid rgb(224, 224, 224);
    margin: 30px 0;
}

.detail-news-box.common-news-box {
    gap: 20px;
    position: sticky;
    top: 115px;
}

.share-btn a.share svg {
    width: 20px;
}

.share-btn a.share {
    color: rgb(0, 0, 0);
    border-color: rgb(0, 0, 0);
    border-width: 2px;
    border-style: solid;
    padding: 9px 30px;
    height: 48px;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    transition: transform 0.5s;
}

.share-btn {
    margin-top: 30px;
}

.share-btn a.share span {
    margin-right: 5px;
}

.share-btn a.share:hover {
    background: #000;
    color: #fff;
}

.social-media-post {
    display: flex;
    gap: 30px;
    margin: 25px 0;
}


/***categoary page style***/

.category-section .common-news-box .cnb-box .cnb-inner {
    flex-direction: column;
    gap: 15px;
}

.category-section .common-news-box .cnb-box .cnb-inner .cnb-img {
    flex: 0 0 100%;
}

.category-section .common-news-box .cnb-box .cnb-inner .cnb-img img {
    height: 100%;
}

.category-section .common-news-box .cnb-box .cnb-content {
    gap: 10px;
}

.category-section .common-news-box .cnb-box .cnb-content .custom-heading.ch-medium {
    -webkit-line-clamp: 3;
}


.category-section .custom-heading.text-center {
    width: 100%;
    text-align: center;
    /* border-bottom: 1px solid #e0e0e0; */
    line-height: 0.1em;
    /* margin: 10px 0 20px; */
    display: block;
}

.category-section .custom-heading span {
    background: #fff;
    padding: 0 10px;
    letter-spacing: 1.5px;
}

/***live News style start***/

.news-card.main-card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.main-card .news-title {
    font-size: 34px;
    font-weight: 600;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    padding-bottom: 0;
}

.news-card.main-card .news-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-container-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 28px;
}

.news-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}


.main-card {
    grid-column: span 2;
}

.news-card {
    background-color: white;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.news-image {
    width: 100%;
    height: auto;
}

.news-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
    transition: all .3s ease-in-out;
    padding: 15px 0 0 0;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.timestamp {
    font-size: 13px;
    color: #999;
    padding: 10px 0 10px;
    margin-bottom: 0;
}

.right-mainnews-inner {
    width: 420px;
    height: 420px;
    overflow: hidden;
}

.news-container-section a.news-card img {
    width: 310px;
    height: 500px;
    overflow: hidden;
    object-fit: cover;
}


.tag {
    background-color: red;
    color: white;
    padding: 2px 8px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 0;
    animation: blink 1.5s infinite;
    margin: 0;
    position: relative;
    top: 5px;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.news-container .news-card:hover .news-title {
    color: var(--light-blue);
    text-decoration: underline;
    text-underline-offset: 3px;
}

.news-container-section .news-card:hover .news-title {
    color: var(--light-blue);
    text-decoration: underline;
    text-underline-offset: 3px;
}

/***live News style End***/

/***cover stories css start***/

.coverstories .flex-box {
    border: none;
}

.coverstories .custom-heading {
    text-transform: uppercase;
    margin: 0 auto;
    font-size: 40px;
    padding: 0 0;
    line-height: 40px;
}

.coverstories .news-container-section {
    margin-top: 40px;
}

.image-container {
    position: relative;
    width: 310px;
    height: 465px;
    overflow: hidden;
}

.overlaysummary {
    position: absolute;
    width: 100%;
    top: auto;
    bottom: 0;
}

.overlay-effect {
    z-index: 2;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0));
    height: 200px;
}

.overlay-text {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.55));
    padding: 0px 1rem 2rem;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
}

/***cover-stories css end***/